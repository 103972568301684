<template>
  <!-- 获取经纬度组件 -->
  <div class="addMap">
    <div id="mapBox" class="mapBox"></div>
  </div>
</template>

<script>
export default {
  props: {
    center: [],
  },
  name: "setMap",
  data() {
    return {
      iconMap: require("../assets/map1.png"),
      marker: "",
      map: "",
      setData: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.addMapCase();
    });
  },
  methods: {
    //   销毁地图
    disposeMap() {
      this.map.dispose();
    },
    // 加载高德地图
    addMapCase() {
      //console.log(this.center,"center-------------------------------------------------")
      this.map = new AMap.Map("mapBox", {
        resizeEnable: true,
        center: [116.397731,39.909348],
        layers:[new AMap.TileLayer.Satellite()],
        zooms: [4, 18], //设置地图级别范围
        zoom: 13,
        //mapStyle: "amap://styles/fresh",
      });
      AMap.event.addListener(this.map, "click", this.clickSet);
    },
    clickSet(e) {
      let _this = this;
      this.map.remove(this.marker);
      var x = e.lnglat.lng;
      var y = e.lnglat.lat;
      //console.log(x, y);
      this.setData = `${x},${y}`;
      this.marker = new AMap.Marker({
        map: this.map,
        // icon: _this.iconMap,
        icon: new AMap.Icon({
          size: new AMap.Size(60, 70),
          image: _this.iconMap,
          imageSize: new AMap.Size(60, 70),
          imageOffset: new AMap.Pixel(0, 0)
        }),
        position: [x, y],
        offset: new AMap.Pixel(-30, -60),
      });
      // this.map.add(this.marker);
      this.map.setFitView();
      this.$emit("getDatas", this.setData);
      //  }
    },
    setMap1(a, b) {
      this.map = new AMap.Map("mapBox", {
        resizeEnable: true,
        center: [a, b],
        layers:[new AMap.TileLayer.Satellite()],
        zooms: [4, 18], //设置地图级别范围
        zoom: 13,
        mapStyle: "amap://styles/fresh",
      });
      AMap.event.addListener(this.map, "click", this.clickSet);
    },
  },
};
</script>

<style>
.addMap {
  width: 100%;
}

.addMap .mapBox {
  width: 100%;
  height: 30vh;
  margin-bottom: 2vh;
  border-radius: 10px;
}
</style>
