<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="primary" size="small" @click="addDevice"
                >添加设备</el-button
              >
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input
                v-model="searchName"
                placeholder="请输入设备名称"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button
            style="margin-left: 1vw"
            type="primary"
            size="small"
            @click="search"
        >搜索
        </el-button
        >
        <el-button
            style="margin-left: 1vw"
            type="primary"
            size="small"
            @click="addAll_anfang(4)"
        >一键同步海康安防平台设备
        </el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh"
    >
      <!-- <el-table-column prop="id" label="序号" align="center"></el-table-column> -->
      <el-table-column
        show-overflow-tooltip
        label="序号"
        align="center"
        :index="indexMethod"
        width="100"
        type="index"
      ></el-table-column>
      <el-table-column
        prop="deviceName"
        label="设备名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="deviceNum"
        label="设备编号"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column prop="brand" label="流媒体平台" show-overflow-tooltip column-key="brand" :filters="[
          { text: '萤石开放平台', value: 1 },
          { text: '乐橙云', value: 2 },
          { text: '国标28181', value: 3 },
          { text: '海康安防平台', value: 4 },
        ]" :filter-method="filterHandler" :filter-multiple="true" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.brand == 1">萤石开放平台</span>
          <span v-if="scope.row.brand == 2">乐橙云</span>
          <span v-if="scope.row.brand == 3">国标28181</span>
          <span v-if="scope.row.brand == 4">海康安防平台</span>
        </template>
      </el-table-column>
<!--      <el-table-column
        prop="verifyCode"
        label="验证码"
        show-overflow-tooltip
        align="center"
      ></el-table-column>-->
      <el-table-column
        prop="channel"
        label="通道编号"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="position" label="坐标" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{ scope.row.longitude }},{{ scope.row.latitude }}
        </template>
      </el-table-column> -->
<!--      <el-table-column
        prop="hls"
        label="设备状态"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span
            :style="{ color: colorList[scope.row.status ? '在线' : '离线'] }"
          >
            {{ scope.row.status ? "在线" : "离线" }}</span
          >
          &lt;!&ndash; <span v-if="scope.row.hls" style="color: green">在线</span>
          <span v-else style="color: red">离线</span> &ndash;&gt;
        </template>
      </el-table-column>-->

      <!-- <el-table-column prop="time" label="数据更新时间" show-overflow-tooltip align="center"></el-table-column> -->
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button @click="detail(scope.row)" type="text" size="small"
            >实时播放</el-button
          >
          <el-button @click="edit(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button @click="del(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加修改 -->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="31vw"
      class="addAlameBox"
      @close="closeAddForm"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        ></span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="10vw"
        >
          <el-form-item label="设备名称" prop="deviceName">
            <el-input
              v-model="addForm.deviceName"
              placeholder="请输入设备名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNum">
            <el-input
                v-model="addForm.deviceNum"
                placeholder="请输入序列号"
            ></el-input>
          </el-form-item>
          <el-form-item label="通道编号" prop="channel">
            <el-input
              v-model="addForm.channel"
              placeholder="请输入通道号"
            ></el-input>
          </el-form-item>

          <el-form-item label="流媒体平台" prop="brand">
            <el-select
              v-model="addForm.brand"
              placeholder="请选择流媒体平台"
            >
              <el-option
                  v-for="item in cameraOwn"
                  :label="item.name"
                  :value="item.id+''"
                  :key="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              v-model="addForm.brand"
              placeholder="请输入摄像头厂家"
            ></el-input> -->
          </el-form-item>
          <el-form-item label="验证码" prop="verifyCode">
            <el-input
              v-model="addForm.verifyCode"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="定时截屏" prop="capture">
            <el-input
              v-model="addForm.verifyCode"
              placeholder="请输入"
            ></el-input> 
          </el-form-item>-->
          <!-- <el-form-item label="定时截屏" prop="capture">
            <el-switch
              v-model="isCapture"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeCapture"
            >
            </el-switch>
          </el-form-item> -->
          <el-form-item label="设备位置" prop="location">
            <el-input v-model="addForm.location"></el-input>
          </el-form-item>
          <!-- <div id="mapBox" class="mapBox"></div> -->
          <set-Map ref="mapPotion" @getDatas="getDatas"></set-Map>
          <!-- <el-form-item label="坐标" prop="position">
            <el-input v-model="addForm.position" placeholder="设备坐标"></el-input>
          </el-form-item>
          <search-map :center1="center1" :setMap="true" @searchLnglat="positionObtain"/> -->
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          v-show="showFlag"
          style="margin-top: 2vh"
        >
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!--    抽屉-->
    <div class="drawerBox">
      <el-dialog
        title="详情"
        :visible.sync="drawer"
        :direction="direction"
        :destroy-on-close="true"
        class="addAlameBox"
        :fullscreen="fullscreen"
      >
        <!-- :before-close="handleClose" -->
        <div class="drawer">
          <!-- <div class="msg">
            <span>设备名称</span><span>{{ this.msgs.name }}</span>
          </div> -->
          <div class="msg" v-if="isState" :style="{ height: videoHeight }">
            <video
              ref="videoPlayer"
              id="videoPlayer"
              style="width: 100%; height: 100%"
              class="video-js"
            ></video>
          </div>
          <div class="msg" v-else>暂无视频</div>
          <div>
            <el-button type="primary" @click="requestFullscreen">{{
              fullscreen ? "取消全屏" : "全屏"
            }}</el-button>
            <el-button type="primary" @click="cameraManageStart(0)"
              >上移</el-button
            >
            <el-button type="primary" @click="cameraManageStart(1)"
              >下移</el-button
            >
            <el-button type="primary" @click="cameraManageStart(2)"
              >左移</el-button
            >
            <el-button type="primary" @click="cameraManageStart(3)"
              >右移</el-button
            >
            <el-button type="primary" @click="cameraManageCapture()"
              >抓拍</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import setMap from "../../../components/setMap.vue";
import initMarkerMap from "../../../components/initMarkerMap.vue";
import Videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";

export default {
  components: { setMap, initMarkerMap },
  data() {
    return {
      videoHeight: "100vh",
      fullscreen: false,
      isState: true,
      colorList: {
        在线: "green",
        离线: "red",
      },
      options: {
        autoplay: true, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: "auto", // 预加载
        controls: true, // 显示播放的控件
      },
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      formTitle: "",
      messageBox: false,
      cameraOwn: [
        {
          id: 1,
          name: "萤石开放平台",
        },
        {
          id: 2,
          name: "乐橙云",
        },
        {
          id: 3,
          name: "国标28181",
        },
        {
          id: 4,
          name: "海康安防平台",
        },
      ],
      showFlag: false,
      ids: 0,
      searchName: "",
      isCapture: true,
      addForm: {
        id: "",
        deviceName: "",
        position: "",
        deviceNum: "",
        channel: "",
        verifyCode: "",
        brand: "",
        deviceType: "1",
        longitude: "",
        latitude: "",
        capture: 1, //是否截图 0 不截图 1 截图
        data7: "",
      },
      addForms: { position: "" },
      rules: {
        deviceNum: [
          {
            required: true,
            message: "设备编号不可为空",
            trigger: ["blur", "change"],
          },
        ],
        deviceName: [
          {
            required: true,
            message: "设备名称不可为空",
            trigger: ["blur", "change"],
          },
        ],
        channel: [
          {
            required: true,
            message: "不可为空",
            trigger: ["blur", "change"],
          },
        ],
        verifyCode: [
          {
            required: false,
            message: "不可为空",
            trigger: ["blur", "change"],
          },
        ],
        brand: [
          {
            required: true,
            message: "不可为空",
            trigger: ["blur", "change"],
          },
        ],
        longitude: [{ required: true, message: "不可为空", trigger: "change" }],
        latitude: [{ required: true, message: "不可为空", trigger: "change" }],
        data7: [{ required: true, message: "不可为空", trigger: "change" }],
        deviceType: [
          {
            required: true,
            message: "请选择设备类型",
            trigger: ["blur", "change"],
          },
        ],
        // position: [{required: true, message: "坐标不可为空", trigger: ["blur", "change"]}],
      },
      direction: "rtl",
      drawer: false,
      msgs: [],
      showData: [],
      center1: [],
      data: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    changeType(val) {
      //console.log("类型", val);
      this.data = val;
      this.deviceType = this.data;
    },
    //请求列表数据
    getList() {
      this.$get("/cameraManage/page", {
        page: this.page,
        limit: this.size,
        deviceName: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          //console.log("请求列表数据", this.tableData);
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 开始控制云台
    cameraManageStart(num) {
      let that = this;
      this.$get(`/cameraManage/start/${this.ids}`, {
        direction: num,
      }).then((res) => {
        if (res.data.state == "success") {
          setTimeout(function () {
            that
              .$get(`/cameraManage/stop/${that.ids}`, {
                direction: num,
              })
              .then(() => {});
          }, 1000);
        }
      });
    },
    // 抓拍
    cameraManageCapture() {
      this.$get(`/cameraManage/capture/${this.ids}`).then((res) => {
        if (res.data.state == "success") {
          // const a = document.createElement("a");
          // a.download = "抓拍";
          // a.href = res.data.data;
          // a.click();
          // document.body.removeChild(a);
          window.open(res.data.data)
        }
      });
    },
    // 播放视频
    getVideos(now) {
      this.player = Videojs(this.$refs.videoPlayer, this.options);
      this.player.src([
        {
          src: now,
          type: "application/x-mpegURL",
        },
      ]);
    },
    // 摄像头厂家
    changeCamera(val) {
      this.addForm.cameraType = val.id;
      this.addForm.brand = val.name;
    },
    // 获取地图组件数据
    getDatas(e) {
      //console.log(e)
      // this.addForm.data7 = e;
      // this.addForm.longitude = e.split(",")[0]; //经度
      // this.addForm.latitude = e.split(",")[1]; //纬度
      this.addForm.location = e
      this.$forceUpdate();
    },
    // 是否截屏
    changeCapture(val) {
      val == true ? (this.addForm.capture = 1) : (this.addForm.capture = 0);
      //console.log(this.addForm.capture);
      //console.log("0不截,1截");
    },
    // 序号
    indexMethod(index) {
      return index + this.size * (this.page - 1) + 1;
    },
    //确认提交
    saveForm(formName) {
      let url;
      if (this.formTitle == "新增") {
        url = "/cameraManage/save";
      } else if (this.formTitle == "编辑") {
        url = "/cameraManage/save";
        //console.log("this.addForm", this.addForm);
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(url, {
            id: this.ids,
            baseId: 1,
            cameraType: this.addForm.cameraType,
            capture: this.addForm.capture,
            latitude: this.addForm.latitude,
            longitude: this.addForm.longitude,
            channel: this.addForm.channel,
            deviceNum: this.addForm.deviceNum,
            verifyCode: this.addForm.verifyCode,
            brand: this.addForm.brand,
            deviceName: this.addForm.deviceName,
            location: this.addForm.location,
            // data7:this.data.longitude + ',' + this.data.latitude
          }).then((res) => {
            if (res.data.state == "success") {
              //console.log("res.data.state ", res.data.state);
              this.$message.success("操作成功");
              this.messageBox = false;
              this.getList();
            } else {
              this.$message.error(res.data.msg);
              this.messageBox = false;
            }
          }).catch(err=>{
            this.$message.error(err.data.msg);
          })
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    // 新增
    addDevice() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.isCapture = true;
      this.ids = 0;
      // this.addForm.name = ''
      // this.addForm.node = ''
      this.$nextTick(() => {
        this.addForm = {};
        // this.addForm = this.addForms;
        this.addForm.capture = 1;
        this.$refs.addForm.resetFields();
        this.$refs.mapPotion.setMap1(116.06, 35.6);
      });
    },
    //编辑
    edit(item) {
      //console.log("item", item);
      this.ids = item.id;
      this.showFlag = true;
      this.formTitle = "编辑";
      this.messageBox = true;
      let that = this;
      this.$nextTick(() => {
        that.addForm = JSON.parse(JSON.stringify(item));
        // that.addForm.data7 =
        // that.addForm.longitude + "," + that.addForm.latitude;
        // that.$refs.mapPotion.setMap1(item.longitude, item.latitude);
        that.$refs.mapPotion.setMap1(
          item.location.split(",")[0],
          item.location.split(",")[1]
        );
      });

      this.addForm.capture == 1
        ? (this.isCapture = true)
        : (this.isCapture = false);
    },
    // 详情
    detail(item) {
      this.ids = item.id;
      this.title = "详情";
      this.drawer = true;
      this.showData = this.tableData;
      this.$get("/cameraManage/realPlay/" + item.id, {
        protocol: 2,
      }).then((res) => {
        //console.log("摄像头", res);
        let _this = this;
        if (res.data.state == "success") {
          let videoHls = res.data.data.url;
          this.$nextTick(() => {
            setTimeout(() => {
              _this.getVideos(videoHls);
            }, 50);
          });
        }
      });

      // for (let i = 0; i < this.showData.length; i++) {
      //   if (item.id == this.showData[i].id) {
      //     this.msgs = this.showData[i];
      //     //console.log(this.msgs);
      //   }
      // }
      // let _this = this;
      // if (item.hls) {
      //   this.isState = true;
      //   this.$nextTick(() => {
      //     setTimeout(() => {
      //       _this.getVideos(item.hls);
      //     }, 50);
      //   });
      // } else {
      //   this.isState = false;
      // }
    },

    // detail(item) {
    //   this.title = "详情";
    //   this.drawer = true;
    //   this.$post("cameraManage/getMonitorAddress", {
    //     id: item.id,
    //     perspective: 0,
    //   }).then((res) => {
    //     if (res.data.state == "success") {
    //       let hls = res.data.data.hls;
    //       //console.log(hls);
    //       if (hls) {
    //         this.isState = true;
    //         this.$nextTick(() => {
    //           setTimeout(() => {
    //             this.getVideos(hls);
    //           }, 50);
    //         });
    //       } else {
    //         this.isState = false;
    //       }
    //     }
    //     if (res.data.state == 500) {
    //       this.$message.error("请检查摄像头的配置是否正确！");
    //     }
    //   });
    // },
    //删除

    del(item) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/cameraManage/delete/${item.id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.info("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //关闭dialog
    closeAddForm() {
      this.resetForm(this.addForm);
    },
    // 搜索
    search() {
      this.page = 1;
      this.getList(name);
    },
    //一键同步海康安防平台设备
    addAll_anfang(brand) {

      this.$put(`/cameraManage/syncHikvision/${brand}`).then((res) => {
        if (res.data.state == "success") {
          console.log(res)
        }
      });
    },
    handleClose() {
      // this.drawer = false;
      if (this.player) {
        this.player.dispose(); // 该方法会重置videojs的内部状态并移除dom
      }
    },
    handleCloses() {
      this.messageBox = false;
      // 销毁地图
      done();
    },
    // 详情抽屉关闭
    handleClosed(done) {
      done();
    },

    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    positionObtain(val) {
      let data = val;
      this.addForm.position = data;
    },
    searchMap() {
      let _this = this;
      AMap.service(["AMap.PlaceSearch"], function () {
        var address = _this.locationsss;
        //构造地点查询类
        var placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          //            city: "010", // 兴趣点城市
          citylimit: true, //是否强制限制在设置的城市内搜索
          map: mapTwo, // 展现结果的地图实例
          //               panel: "msg", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        //关键字查询
        placeSearch.search(address);
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    requestFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.videoHeight = "80vh";
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
  max-height: 40vh !important;
}

.cell /deep/.el-button--small {
  color: #409eff;
}
.videoMsg {
  position: relative;
}
.videoBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
