<template>
  <!-- 标点map -->
  <div class="initMap">
    <div id="maekerMapBox" class="maekerMapBox"></div>
  </div>
</template>

<script>
export default {
  name: "initMarkerMap",
  props: {
    center:[],
    iconList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      iconMap: require("../assets/map1.png"),
      marker: "",
      map: "",
      setData: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.addMapCase();
    });
  },
  methods: {
    //   销毁地图
    disposeMap() {
      this.map.dispose();
    },
    // 加载高德地图
    addMapCase() {
      this.map = new AMap.Map("maekerMapBox", {
        resizeEnable: true,
        center:this.center.length ? this.center :[116.397731,39.909348],
        zooms: [4, 18], //设置地图级别范围
        zoom: 13,
        mapStyle: "amap://styles/fresh",
      });
      let _this = this;
      this.map.remove(this.marker);
      if (this.iconList[0]) {
        this.iconList.forEach((val) => {
          this.marker = new AMap.Marker({
            map: this.map,
            icon: this.iconMap,
            position: [val.lng, val.lat],
            offset: new AMap.Pixel(-5, -30),
          });
        });
      }
      // this.map.add(this.marker);
      this.map.setFitView();
    },
  },
};
</script>

<style>
.initMap {
  width: 100%;
}

.initMap .maekerMapBox {
  width: 100%;
  height: 18vh;
  margin-bottom: 2vh;
  border-radius: 10px;
}
</style>
